const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY; // eslint-disable-line @typescript-eslint/no-magic-numbers
const YEAR = 365 * DAY; // eslint-disable-line @typescript-eslint/no-magic-numbers

export function formatDate(date: Date): string {
  const ms = Date.now() - date.getTime();
  if (ms < MINUTE) {
    return 'now';
  }
  const [num, label] = dateComponent(ms);
  return `${num.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}${label}`;
}

function dateComponent(ms: number): [number, string] {
  if (ms < HOUR) {
    return [ms / MINUTE, ` Min`];
  }
  if (ms < DAY) {
    return [ms / HOUR, ` Hrs`];
  }
  if (ms < MONTH) {
    return [ms / DAY, ` Day`];
  }
  if (ms < YEAR) {
    return [ms / MONTH, ` Mon`];
  }
  return [ms / YEAR, ` Yrs`];
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function formatReleaseDate(ts?: number): string {
  if (ts === undefined) {
    return '';
  }
  const date = new Date(ts);
  const month = MONTHS[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function formatRuntime(runtime: number | undefined): string {
  return runtime === undefined
    ? ''
    : runtime >= 60
      ? `${Math.floor(runtime / 60)}h ${runtime % 60}m`
      : `${runtime}m`;
}
