import {useCallback} from 'react';
import {styled} from 'styled-components';

import {ButtonAsLink} from '@shared-web/components/core/button';

import {formatDate} from '@src/lib/date';
import {queueCheck, useCheckingQueue} from '@src/stores/checking_queue_store';
import {useNzbsu} from '@src/stores/nzbsu_store';

interface NzbCheckProps {
  guid: string;
}

export const NzbCheck: React.FC<NzbCheckProps> = props => {
  const {guid} = props;
  const item = useNzbsu(guid);

  const checkStatus = useCheckingQueue(item?.guid ?? '');
  const handleItemCheckClick = useCallback(() => queueCheck(guid), [guid]);

  const checkColor = item
    ? item.lastCheck
      ? item.lastCheck.failure === 0
        ? '#00ff0088'
        : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          item.lastCheck.success > 0 && item.lastCheck.failure / item.lastCheck.success < 0.03
          ? '#ff800080'
          : '#ff000080'
      : '#ffffff44'
    : '';
  const checkTitle = item?.lastCheck
    ? `${item.lastCheck.success}/${item.lastCheck.failure}/${item.lastCheck.total} (${formatDate(
        new Date(item.lastCheck.ts)
      )})`
    : 'Never checked';

  return (
    <Wrapper>
      <CheckCircle title={checkTitle} $color={checkColor}></CheckCircle>
      {/* <CheckLabel>{checkLabel}</CheckLabel> */}
      <ButtonAsLink
        data-guid={item?.guid}
        onClick={handleItemCheckClick}
        disabled={checkStatus?.stage === 'queued'}
        loading={checkStatus?.stage === 'inprogress'}
      >
        {checkStatus?.stage === 'inprogress'
          ? 'checking...'
          : checkStatus?.stage === 'queued'
            ? 'queued'
            : item?.lastCheck
              ? 're-check'
              : 'check'}
      </ButtonAsLink>
    </Wrapper>
  );
};
NzbCheck.displayName = 'NzbCheck';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CheckCircle = styled.div<{$color: string}>`
  background-color: ${p => p.$color};
  width: 16px;
  height: 16px;
  border-radius: 16px;
`;
