import {FrontendNzbsu} from '@shared/api/website_api';
import {removeUndefined} from '@shared/lib/type_utils';
import {NzbsuSearchSort} from '@shared/models';

import {createMapStore} from '@shared-web/lib/map_data_store';

const nzbsuStore = createMapStore<string, FrontendNzbsu>();

export const useAllNzbsu = nzbsuStore.useAllData;
export const useNzbsu = nzbsuStore.useData;
export const setNzbsu = nzbsuStore.setData;
export const getNzbsu = nzbsuStore.getData;
export const batchSetNzbsu = nzbsuStore.batchSetData;

const SORTS: {
  sort: NzbsuSearchSort;
  get: (item: FrontendNzbsu) => number;
  desc: boolean;
}[] = [
  {sort: NzbsuSearchSort.SizeDesc, get: item => item.size, desc: true},
  {sort: NzbsuSearchSort.PostedDesc, get: item => item.pubTs, desc: true},
  {sort: NzbsuSearchSort.StatsDesc, get: item => item.grabs ?? 0, desc: true},
  {sort: NzbsuSearchSort.SizeAsc, get: item => item.size, desc: false},
  {sort: NzbsuSearchSort.PostedAsc, get: item => item.pubTs, desc: false},
  {sort: NzbsuSearchSort.StatsAsc, get: item => item.grabs ?? 0, desc: false},
];

export function sortNzbsuIds(ids: string[], sort: NzbsuSearchSort): string[] {
  return removeUndefined(ids.map(id => getNzbsu(id)))
    .sort((i1, i2) => {
      const s = SORTS.find(s => s.sort === sort);
      if (!s) {
        return i1.title.localeCompare(i2.title);
      }
      const val1 = s.get(i1);
      const val2 = s.get(i2);
      return s.desc ? val2 - val1 : val1 - val2;
    })
    .map(item => item.guid);
}
