import {MouseEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {OpenSubtitle, TmdbVideo} from '@shared/api/website_api';
import {isNull} from '@shared/lib/type_utils';
import {NzbsuSearchSort} from '@shared/models';

import {UnthemedButton} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {chevronLeftIcon} from '@shared-web/components/icons/chevron_left_icon';
import {notifyError} from '@shared-web/lib/notification';

import {MovieDetails} from '@src/components/movie_details';
import {NzbTable} from '@src/components/nzb_table';
import {SubtitlesTable} from '@src/components/subtitles_table';
import {apiCall} from '@src/lib/api';
import {batchSetNzbsu, sortNzbsuIds} from '@src/stores/nzbsu_store';
import {setTmdb, useTmdb} from '@src/stores/tmdb_store';

interface BrowseMoviePageProps {
  params: {imdbId: string};
}

const SUBTITLES_LANGS = ['en', 'fr'];

export const BrowseMoviePage: React.FC<BrowseMoviePageProps> = ({params}) => {
  const {imdbId} = params;
  const [_, setLocation] = useLocation();
  const tmdb = useTmdb(imdbId);
  const [nzbIds, setNzbIds] = useState<string[] | undefined>();
  const [sort, setSort] = useState(NzbsuSearchSort.SizeDesc);
  const [videos, setVideos] = useState<TmdbVideo[] | undefined>();
  const handleSortChange = setSort;

  const [subtitles, setSubtitles] = useState<OpenSubtitle[] | undefined>();
  const [subtitlesLang, setSubtitlesLang] = useState<string | undefined>();

  useEffect(() => {
    if (!tmdb) {
      apiCall('POST /get-movie', {imdbId})
        .then(({movie}) => setTmdb(movie.imdbId, movie))
        .catch(notifyError);
    }
  }, [imdbId, tmdb]);

  useEffect(() => {
    if (tmdb) {
      apiCall('POST /get-movie-videos', {tmdbId: tmdb.id})
        .then(({videos}) => setVideos(videos))
        .catch(notifyError);
    }
  }, [imdbId, tmdb]);

  useEffect(() => {
    apiCall('POST /list-nzbsu', {imdbId})
      .then(res => {
        batchSetNzbsu(res.items.map(item => ({key: item.guid, value: item})));
        setNzbIds(res.items.map(item => item.guid));
      })
      .catch(notifyError);
  }, [imdbId]);

  useEffect(() => {
    if (subtitlesLang === undefined) {
      return;
    }
    setSubtitles(undefined);
    apiCall('POST /list-subtitles', {imdbId, language: subtitlesLang})
      .then(({subtitles}) => setSubtitles(subtitles))
      .catch(notifyError);
  }, [imdbId, subtitlesLang]);

  const handleBackClick = useCallback(() => {
    setLocation('/browse');
  }, [setLocation]);

  const handleSubtitleLangClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    const newLang = evt.currentTarget.getAttribute('data-lang');
    if (isNull(newLang)) {
      return;
    }
    setSubtitlesLang(newLang);
  }, []);

  const sortedIds = useMemo(
    () => (nzbIds ? sortNzbsuIds(nzbIds, sort) : undefined),
    [nzbIds, sort]
  );

  return (
    <Wrapper>
      <BackButton onClick={handleBackClick}>
        <SvgIcon icon={chevronLeftIcon} color="#ffffffdd" height={12} />
        Back
      </BackButton>
      {!tmdb ? <div>Loading movie info...</div> : <MovieDetails movie={tmdb} videos={videos} />}
      {sortedIds ? (
        <NzbTable
          ids={sortedIds}
          total={sortedIds.length}
          sort={sort}
          onSortChange={handleSortChange}
          durationSeconds={tmdb?.runtime !== undefined ? tmdb.runtime * 60 : undefined}
        />
      ) : (
        <div>Loading NZB...</div>
      )}
      <SubtitlesWrapper>
        <SubtitleHeader>
          <SubtitleHeaderTitle>Subtitles</SubtitleHeaderTitle>
          <SubtitleLangButtonLine>
            {SUBTITLES_LANGS.map(lang => (
              <SubtitleLangButtonLineItem
                key={lang}
                data-lang={lang}
                $selected={lang === subtitlesLang}
                onClick={handleSubtitleLangClick}
              >
                {lang}
              </SubtitleLangButtonLineItem>
            ))}
          </SubtitleLangButtonLine>
        </SubtitleHeader>
        {subtitles ? (
          <SubtitlesTable subtitles={subtitles} />
        ) : subtitlesLang !== undefined ? (
          <div>Loading subtitles...</div>
        ) : (
          <></>
        )}
      </SubtitlesWrapper>
    </Wrapper>
  );
};
BrowseMoviePage.displayName = 'BrowseMoviePage';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BackButton = styled(UnthemedButton)`
  background: none;
  color: #ffffffdd;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 10px;
  margin-left: -10px;
  border-radius: 4px;
  font-size: 18px;
  &:hover {
    background-color: #ffffff11;
  }
`;

const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubtitleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 10px 0;
`;
const SubtitleHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const SubtitleLangButtonLine = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
`;

const SubtitleLangButtonLineItem = styled.div<{$selected: boolean}>`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: #ffffff11;
  border-radius: 16px;
  border: solid 2px ${p => (p.$selected ? '#a34c00' : 'transparent')};
  &:hover {
    background: #ffffff22;
  }
`;
