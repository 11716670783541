import {
  Arr,
  Bool,
  Num,
  Obj,
  Opt,
  OptNum,
  OptStr,
  SchemaToType,
  Str,
  Unknown,
} from '@shared/api/core/api_schema';

const NzbPreviewSchema = Obj({
  guid: Str(),
  size: Num(),
  pubTs: Num(),
  title: Str(),
});
export type NzbPreview = SchemaToType<typeof NzbPreviewSchema>;

const FrontendTmdbSchema = Obj({
  id: Num(),
  title: Str(),
  posterPath: OptStr(),
  backdropPath: OptStr(),
  releaseDate: OptNum(),
  genres: Arr(
    Obj({
      name: Str(),
      id: Num(),
    })
  ),
  voteAverage: OptNum(),
  voteCount: OptNum(),
  budget: OptNum(),
  revenue: OptNum(),
  runtime: OptNum(),
  tagline: OptStr(),
  overview: OptStr(),
  originalLanguage: OptStr(),
  popularity: OptNum(),

  imdbId: Str(),
  firstPubTs: Num(),
  firstNzb: NzbPreviewSchema,
  lastNzb: NzbPreviewSchema,
  largestNzb: NzbPreviewSchema,
  lastUpdate: Num(),
});
export type FrontendTmdb = SchemaToType<typeof FrontendTmdbSchema>;

const NzbCheckResultSchema = Obj({
  id: Str(),
  nzbId: Str(),
  total: Num(),
  checked: Num(),
  success: Num(),
  failure: Num(),
  ts: Num(),
});
export type NzbCheckResult = SchemaToType<typeof NzbCheckResultSchema>;

const FrontendNzbsuSchema = Obj({
  guid: Str(),
  title: Str(),
  nfo: Bool(),
  size: Num(),
  pubTs: Num(),
  imdb: Str(),
  nzb: Str(),
  grabs: OptNum(),
  lastCheck: Opt(NzbCheckResultSchema),
});
export type FrontendNzbsu = SchemaToType<typeof FrontendNzbsuSchema>;

const TmdbVideoSchema = Obj({
  iso_639_1: Str(),
  iso_3166_1: Str(),
  name: Str(),
  key: Str(),
  site: Str(),
  size: Num(),
  type: Str(),
  official: Bool(),
  published_at: Str(),
  id: Str(),
});
export type TmdbVideo = SchemaToType<typeof TmdbVideoSchema>;

const OpenSubtitleSchema = Obj({
  id: Str(),
  release: Str(),
  language: Str(),
  download_count: Num(),
  upload_date: Num(),
  ai_translated: Bool(),
  machine_translated: Bool(),
  hearing_impaired: Bool(),
  hd: Bool(),
  from_trusted: Bool(),
  foreign_parts_only: Bool(),
  comments: Str(),
  files: Arr(
    Obj({
      file_id: Num(),
      file_name: Str(),
    })
  ),
});
export type OpenSubtitle = SchemaToType<typeof OpenSubtitleSchema>;

export const WEBSITE_API = {
  '/search-nzbsu': {
    POST: {
      req: Obj({query: Str(), cat: Num(), offset: Num(), sort: Str()}),
      res: Obj({
        items: Arr(FrontendNzbsuSchema),
        offset: Num(),
        total: Num(),
      }),
    },
  },
  '/list-nzbsu': {
    POST: {
      req: Obj({imdbId: Str()}),
      res: Obj({
        items: Arr(FrontendNzbsuSchema),
      }),
    },
  },
  '/check-nzb': {
    POST: {
      req: Obj({id: Str()}),
      res: Unknown(),
    },
  },
  '/get-nzb-link': {
    POST: {
      req: Obj({id: Str(), fileName: Str()}),
      res: Obj({url: Str()}),
    },
  },
  '/list-movies': {
    POST: {
      req: Obj({paginationToken: OptStr(), limit: OptNum()}),
      res: Obj({movies: Arr(FrontendTmdbSchema), paginationToken: OptStr()}),
    },
  },
  '/get-movie': {
    POST: {
      req: Obj({imdbId: Str()}),
      res: Obj({movie: FrontendTmdbSchema}),
    },
  },
  '/get-movie-videos': {
    POST: {
      req: Obj({tmdbId: Num()}),
      res: Obj({videos: Arr(TmdbVideoSchema)}),
    },
  },
  '/list-subtitles': {
    POST: {
      req: Obj({imdbId: Str(), language: Str()}),
      res: Obj({subtitles: Arr(OpenSubtitleSchema)}),
    },
  },
  '/get-subtitle-link': {
    POST: {
      req: Obj({id: Num(), fileName: Str()}),
      res: Obj({url: Str()}),
    },
  },
  '/get-nzb-nfo': {
    POST: {
      req: Obj({id: Str()}),
      res: Obj({nfo: OptStr()}),
    },
  },
};
