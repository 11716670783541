import {SvgIconData} from '@shared-web/components/core/svg_icon';

export const deafIcon: SvgIconData = {
  viewBox: '15 5 70 90',
  element: (
    <g>
      <path d="M85 40a35 35 0 0 0-70 0h7.5a27.5 27.5 0 1 1 36.4 26A19 19 0 0 0 47 78.5c-1.6 5.3-6.5 9-12 9V95a20 20 0 0 0 19.2-14.4c1-3.5 3.7-6.3 7.2-7.5A35 35 0 0 0 85 40z"></path>
      <path d="m59.3 51.4 1.6 4.8A17 17 0 0 0 72.5 40a22.5 22.5 0 0 0-45 0h5a17.5 17.5 0 0 1 35 0 12 12 0 0 1-8.2 11.4z"></path>
      <path d="m40 72.5 5-5-8.7-8.7L45 50l-5-5-8.7 8.8-8.8-8.8-5 5 8.8 8.8-8.8 8.7 5 5 8.8-8.7z"></path>
    </g>
  ),
};
