import {FrontendTmdb} from '@shared/api/website_api';

import {createMapStore} from '@shared-web/lib/map_data_store';
import {notifyError} from '@shared-web/lib/notification';

import {apiCall} from '@src/lib/api';
import {getTmdbCount, updateTmdbCount} from '@src/stores/tmdb_count_store';

const tmdbStore = createMapStore<string, FrontendTmdb>();

export const useAllTmdb = tmdbStore.useAllData;
export const useTmdb = tmdbStore.useData;
export const setTmdb = tmdbStore.setData;

let fetchingStarted = false;
export function startFetching(): void {
  if (!fetchingStarted) {
    fetchingStarted = true;
    fetchNextPage();
  }
}

const MAX_TMDB = 10000;

function fetchNextPage(paginationToken?: string): void {
  apiCall('POST /list-movies', {paginationToken, limit: getTmdbCount() === 0 ? 100 : 1000})
    .then(res => {
      for (const movie of res.movies) {
        setTmdb(movie.imdbId, movie);
      }
      updateTmdbCount(count => count + res.movies.length);
      if (getTmdbCount() < MAX_TMDB && res.paginationToken !== undefined) {
        fetchNextPage(res.paginationToken);
      }
    })
    .catch(notifyError);
}
