import {FC, MouseEvent, useCallback} from 'react';
import {styled} from 'styled-components';

import {OpenSubtitle} from '@shared/api/website_api';

import {ButtonAsLink} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {deafIcon} from '@shared-web/components/custom_icons/deaf_icon';
import {internationalIcon} from '@shared-web/components/custom_icons/international_icon';
import {trustedIcon} from '@shared-web/components/custom_icons/trusted_icon';
import {downloadIcon} from '@shared-web/components/icons/download_icon';
import {notifyError} from '@shared-web/lib/notification';

import {apiCall} from '@src/lib/api';
import {setSubtitleDownloadLink, useAllSubtitleDownloadLink} from '@src/stores/subtitle_link_store';

interface SubtitlesTableProps {
  subtitles: OpenSubtitle[];
}

function subtitleScore(subtitle: OpenSubtitle): number {
  const {
    ai_translated,
    machine_translated,
    foreign_parts_only,
    from_trusted,
    hearing_impaired,
    hd,
    download_count,
  } = subtitle;
  let score = 0;
  if (foreign_parts_only) {
    score -= 1000 * 1000;
  }
  if (ai_translated || machine_translated) {
    score -= 100 * 1000;
  }
  if (hearing_impaired) {
    score -= 10 * 1000;
  }
  if (from_trusted) {
    score += 100;
  }
  if (hd) {
    score += 1000;
  }

  score += download_count / (1000 * 1000 * 1000);

  return score;
}

export const SubtitlesTable: FC<SubtitlesTableProps> = props => {
  const {subtitles} = props;

  const downloadLinks = useAllSubtitleDownloadLink();

  const handleDownloadClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      // Get the download URL
      const id = evt.currentTarget.getAttribute('data-id');
      if (id === null) {
        return;
      }
      const subtitle = subtitles.find(item => item.id === id);
      const {file_id, file_name = 'noname'} = subtitle?.files[0] ?? {};
      if (file_id === undefined) {
        notifyError(new Error('Nothing to download'));
        return;
      }
      let link = downloadLinks.get(file_id);

      const fileName = file_name.endsWith('.srt') ? file_name : `${file_name}.srt`;

      // Fetch if not in store
      if (link === undefined) {
        const {url} = await apiCall('POST /get-subtitle-link', {id: file_id, fileName});
        setSubtitleDownloadLink(file_id, url);
        link = url;
      }

      // Start the download
      const element = document.createElement('a');
      element.setAttribute('href', link);
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    [downloadLinks, subtitles]
  );

  return (
    <Wrapper>
      {subtitles
        .sort((sub1, sub2) => subtitleScore(sub2) - subtitleScore(sub1))
        .map(subtitle => (
          <Line key={subtitle.id}>
            <SubtitleName>
              <div title={subtitle.comments}>{subtitle.release}</div>
              <SubtitleIcons>
                {[
                  {
                    attr: 'ai_translated' as const,
                    icon: (
                      <TextIcon key="ai" title="AI translated" $bad>
                        AI
                      </TextIcon>
                    ),
                  },
                  {
                    attr: 'machine_translated' as const,
                    icon: (
                      <TextIcon key="machine" title="Machine translated" $bad>
                        AI
                      </TextIcon>
                    ),
                  },
                  {
                    attr: 'hearing_impaired' as const,
                    icon: (
                      <SubtitleIconWrapper title="Subtitles for hearing impaired">
                        <SubtitleIcon
                          key="deaf"
                          $bad
                          icon={deafIcon}
                          width={23}
                          height={20}
                          color="#ffffff"
                        />
                      </SubtitleIconWrapper>
                    ),
                  },
                  {
                    attr: 'hd' as const,

                    icon: <TextIcon title="Subtitles for high-definition movie">HD</TextIcon>,
                  },
                  {
                    attr: 'from_trusted' as const,

                    icon: (
                      <SubtitleIconWrapper title="Subtitles from trusted source">
                        <SubtitleIcon
                          key="trusted"
                          icon={trustedIcon}
                          width={23}
                          size={18}
                          color="#ffffff"
                        />
                      </SubtitleIconWrapper>
                    ),
                  },
                  {
                    attr: 'foreign_parts_only' as const,
                    icon: (
                      <SubtitleIconWrapper title="Foreign parts only">
                        <SubtitleIcon
                          key="foreign"
                          $bad
                          icon={internationalIcon}
                          width={23}
                          height={20}
                          color="#ffffff"
                        />
                      </SubtitleIconWrapper>
                    ),
                  },
                ].map(flag => (subtitle[flag.attr] ? flag.icon : false))}
              </SubtitleIcons>
            </SubtitleName>
            <SubtitleDownloadCount>
              {subtitle.download_count.toLocaleString()}
            </SubtitleDownloadCount>
            <Download>
              <DownloadButton data-id={subtitle.id} onClickAsync={handleDownloadClick}>
                <SvgIcon
                  icon={downloadIcon}
                  color={downloadLinks.has(subtitle.files[0]?.file_id ?? 0) ? '#2e6f31' : '#db7b26'}
                  size={16}
                />
              </DownloadButton>
            </Download>
          </Line>
        ))}
    </Wrapper>
  );
};

SubtitlesTable.displayName = 'SubtitlesTable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff10;
  border-radius: 4px;
  padding: 4px 8px;
`;

const Col = styled.div`
  flex-shrink: 0;
  width: 48px;
  padding: 0 8px;
`;

const SubtitleName = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  word-break: break-all;
`;
const SubtitleIcons = styled.div`
  float: right;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const SubtitleIcon = styled(SvgIcon)<{$bad?: boolean}>`
  background-color: ${p => (p.$bad ? '#ff606082' : '#00ff1177')};
  border-radius: 3px;
  padding: 2px 0;
`;
const SubtitleIconWrapper = styled.div`
  display: flex;
`;
const TextIcon = styled.div<{$bad?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  border-radius: 3px;
  width: 23px;
  height: 20px;
  background-color: ${p => (p.$bad ? '#ff606082' : '#00ff1177')};
  color: #ffffff;
`;

const SubtitleDownloadCount = styled(Col)`
  width: 80px;
  text-align: right;
`;

const Download = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadButton = styled(ButtonAsLink)`
  justify-content: center;
  width: 30px;
  height: 24px;
  border-radius: 8px;
  &:hover {
    background-color: #ffffff20;
  }
`;
