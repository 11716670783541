import {NzbCheckResult} from '@shared/api/website_api';

import {createMapStore} from '@shared-web/lib/map_data_store';

import {apiCall} from '@src/lib/api';
import {getNzbsu, setNzbsu} from '@src/stores/nzbsu_store';

export type QueueStatus =
  | {stage: 'queued'; guid: string; cancel: () => void}
  | {stage: 'inprogress'; guid: string; cancel: () => void}
  | {stage: 'failed'; guid: string; err: unknown}
  | {stage: 'success'; guid: string; checkResult: NzbCheckResult};

const checkingQueueStore = createMapStore<string, QueueStatus>();
export const useCheckingQueue = checkingQueueStore.useData;
const setCheckingQueue = checkingQueueStore.setData;
export const getCheckingQueue = checkingQueueStore.getData;

const internalQueue: string[] = [];

let inProgress: string | undefined;
let inProgressCanceled: string | undefined;

function processQueue(): void {
  if (inProgress !== undefined) {
    return;
  }
  const guid = internalQueue.shift();
  if (guid === undefined) {
    return;
  }

  inProgress = guid;
  const currentStatus = getCheckingQueue(guid);
  setCheckingQueue(guid, {
    stage: 'inprogress',
    guid,
    cancel: currentStatus?.stage === 'queued' ? currentStatus.cancel : () => {},
  });

  apiCall('POST /check-nzb', {id: guid})
    .then(res => {
      inProgress = undefined;
      if (inProgressCanceled === guid) {
        processQueue();
        return;
      }
      const checkResult = res as NzbCheckResult;
      setCheckingQueue(guid, {stage: 'success', guid, checkResult});
      const current = getNzbsu(guid);
      if (current) {
        setNzbsu(guid, {...current, lastCheck: checkResult});
      }
      processQueue();
    })
    .catch(err => {
      inProgress = undefined;
      if (inProgressCanceled === guid) {
        processQueue();
        return;
      }
      setCheckingQueue(guid, {stage: 'failed', guid, err});
      processQueue();
    });
}

export function queueCheck(guid: string): void {
  internalQueue.push(guid);

  function cancel(): void {
    const index = internalQueue.indexOf(guid);
    if (index !== -1) {
      internalQueue.splice(index, 1);
    }
    if (inProgress === guid) {
      inProgressCanceled = guid;
    }
  }

  setCheckingQueue(guid, {stage: 'queued', guid, cancel});
  processQueue();
}
