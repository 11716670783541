import {ChangeEvent, MouseEvent, useCallback, useMemo} from 'react';
import {styled} from 'styled-components';

import {isNull, NonEmptyArray} from '@shared/lib/type_utils';

import {ButtonAsLink} from '@shared-web/components/core/button';
import {Input} from '@shared-web/components/core/input';
import {Spacing} from '@shared-web/components/core/spacing';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {squareIcon} from '@shared-web/components/icons/square_icon';
import {Custom} from '@shared-web/lib/react';

import {
  GenreFilter,
  TmdbSort,
  updateTmdbSearchBar,
  useTmdbSearchBar,
} from '@src/stores/tmdb_search_bar_store';
import {useAllTmdb} from '@src/stores/tmdb_store';

interface SearchBarProps {}

const allSorts: {value: TmdbSort; label: string}[] = [
  {value: TmdbSort.Recents, label: 'Recents'},
  {value: TmdbSort.ReleaseDate, label: 'Release Date'},
  {value: TmdbSort.Score, label: 'Score'},
  {value: TmdbSort.Popularity, label: 'Popularity'},
  {value: TmdbSort.Budget, label: 'Budget'},
  {value: TmdbSort.BoxOffice, label: 'Box Office'},
];

/* eslint-disable @typescript-eslint/no-magic-numbers */
const WEEKS = 7 * 24 * 3600 * 1000;
const MONTHS = 30.5 * 24 * 3600 * 1000;
const YEARS = 365 * 24 * 3600 * 1000;

const maxAgeSteps: NonEmptyArray<{value: number; label: string; hasButton?: true}> = [
  {value: Number.POSITIVE_INFINITY, label: 'All', hasButton: true},
  {value: 10 * YEARS, label: '10y', hasButton: true},
  {value: 8 * YEARS, label: '8y'},
  {value: 5 * YEARS, label: '5y'},
  {value: 4 * YEARS, label: '4y'},
  {value: 3 * YEARS, label: '3y'},
  {value: 2 * YEARS, label: '2y', hasButton: true},
  {value: YEARS, label: '1y'},
  {value: 10 * MONTHS, label: '10m'},
  {value: 8 * MONTHS, label: '8m'},
  {value: 6 * MONTHS, label: '6m', hasButton: true},
  {value: 5 * MONTHS, label: '5m'},
  {value: 4 * MONTHS, label: '4m'},
  {value: 3 * MONTHS, label: '3m'},
  {value: MONTHS, label: '1m', hasButton: true},
  {value: 3 * WEEKS, label: '3w'},
  {value: 2 * WEEKS, label: '2w'},
  {value: WEEKS, label: '1w', hasButton: true},
];
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const SearchBar: Custom<SearchBarProps, 'div'> = props => {
  const {...rest} = props;
  const {genres, minScore, maxAge, sort, title} = useTmdbSearchBar();
  const allTmdb = useAllTmdb();

  const allGenres = useMemo(() => {
    const genres: Record<string, number> = {};
    for (const item of allTmdb.values()) {
      for (const genre of item.genres) {
        genres[genre.name] = (genres[genre.name] ?? 0) + 1;
      }
    }
    return Object.entries(genres).sort((e1, e2) => e1[0].localeCompare(e2[0]));
  }, [allTmdb]);

  // TITLE
  const updateTitle = useCallback((newTitle: string) => {
    updateTmdbSearchBar(current => ({...current, title: newTitle}));
  }, []);

  // GENRE
  const handleGenreClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    const genre = evt.currentTarget.getAttribute('data-genre');
    if (isNull(genre)) {
      return;
    }
    updateTmdbSearchBar(current => {
      const currentGenreValue = current.genres.find(g => g.name === genre);
      const newGenreValue =
        currentGenreValue === undefined
          ? [
              {
                name: genre,
                mode: GenreFilter.Include,
              },
            ]
          : currentGenreValue.mode === GenreFilter.Include
            ? [
                {
                  name: genre,
                  mode: GenreFilter.Exclude,
                },
              ]
            : [];
      return {
        ...current,
        genres: [...current.genres.filter(c => c.name !== genre), ...newGenreValue],
      };
    });
  }, []);

  // SCORE
  const handleScoreChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const valueStr = evt.currentTarget.value;
    const value = parseFloat(valueStr);
    updateTmdbSearchBar(current => ({...current, minScore: value}));
  }, []);
  const handleScoreClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    const valueStr = evt.currentTarget.getAttribute('data-score');
    if (isNull(valueStr)) {
      return;
    }
    const value = parseFloat(valueStr);
    updateTmdbSearchBar(current => ({...current, minScore: value}));
  }, []);

  // MAX AGE
  const handleMaxAgeChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const valueStr = evt.currentTarget.value;
    const index = parseFloat(valueStr);
    updateTmdbSearchBar(current => ({
      ...current,
      maxAge: (maxAgeSteps[index] ?? maxAgeSteps[0]).value,
    }));
  }, []);
  const handleMaxAgeClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    const valueStr = evt.currentTarget.getAttribute('data-index');
    if (isNull(valueStr)) {
      return;
    }
    const index = parseFloat(valueStr);
    const step = maxAgeSteps[index];
    if (step) {
      updateTmdbSearchBar(current => ({...current, maxAge: step.value}));
    }
  }, []);

  // SORT
  const handleSortClick = useCallback((evt: MouseEvent<HTMLElement>) => {
    const valueStr = evt.currentTarget.getAttribute('data-sort');
    if (isNull(valueStr)) {
      return;
    }
    updateTmdbSearchBar(current => ({...current, sort: valueStr as TmdbSort}));
  }, []);

  return (
    <Wrapper {...rest}>
      <TitleHeader>TITLE</TitleHeader>
      <TitleWrapper>
        <Input value={title} syncState={updateTitle} autoFocus />
      </TitleWrapper>

      <Spacing height={24} />

      <SortsHeader>SORT BY</SortsHeader>

      <Sorts>
        {allSorts.map(({value, label}) => (
          <Sort key={value} data-sort={value} $selected={sort === value} onClick={handleSortClick}>
            <SvgIcon icon={squareIcon} color={sort === value ? '#a34c00' : '#454a53'} size={16} />
            {label}
          </Sort>
        ))}
      </Sorts>

      <Spacing height={24} />

      <ScoreHeader>MIN SCORE</ScoreHeader>
      <RangeWrapper>
        <RangeInput
          type="range"
          min={0}
          max={100}
          step={1}
          value={minScore}
          onChange={handleScoreChange}
        />
        <RangeButtonLabel>{minScore}</RangeButtonLabel>
      </RangeWrapper>
      <RangeButtons>
        {[
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          50, 60, 70, 80, 85,
        ].map(score => (
          <RangeButton key={score} data-score={score} onClick={handleScoreClick}>
            <RangeButtonLabel>{score}</RangeButtonLabel>
          </RangeButton>
        ))}
      </RangeButtons>

      <Spacing height={24} />

      <AgeHeader>MAX AGE</AgeHeader>
      <RangeWrapper>
        <RangeInput
          type="range"
          min={0}
          max={maxAgeSteps.length - 1}
          step={1}
          value={maxAgeSteps.findIndex(step => step.value === maxAge)}
          onChange={handleMaxAgeChange}
        />
        <RangeButtonLabel>
          {maxAgeSteps.find(step => step.value === maxAge)?.label}
        </RangeButtonLabel>
      </RangeWrapper>
      <RangeButtons>
        {maxAgeSteps
          .filter(step => step.hasButton)
          .map(({label, value}, i) => (
            <RangeButton key={value} data-index={i} onClick={handleMaxAgeClick}>
              <RangeButtonLabel>{label}</RangeButtonLabel>
            </RangeButton>
          ))}
      </RangeButtons>

      <Spacing height={24} />

      <GenresHeader>GENRES</GenresHeader>
      <Genres>
        {allGenres.map(([genre, count]) => {
          const mode = genres.find(g => g.name === genre)?.mode;
          const color =
            mode === undefined ? '#bbbbbb' : mode === GenreFilter.Include ? '#21d07a' : '#db2360';
          return (
            <Genre key={genre} data-genre={genre} $color={color} onClick={handleGenreClick}>
              <GenreControl>
                <SvgIcon icon={squareIcon} color={color} size={16} />
                {genre}
              </GenreControl>
              <GenreCount>{count}</GenreCount>
            </Genre>
          );
        })}
      </Genres>
    </Wrapper>
  );
};

SearchBar.displayName = 'SearchBar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #1f242f;
  padding: 16px 0;
  border-radius: 8px;
`;

const Header = styled.div`
  font-size: 16px;
  color: #aaa;
  padding: 8px 16px;
  text-align: center;
`;

// TITLE

const TitleHeader = styled(Header)``;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;
`;

// GENRE

const GenresHeader = styled(Header)``;

const Genres = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 15px;
`;

const Genre = styled.div<{$color: string}>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  color: ${p => p.$color};
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #ffffff10;
  }
`;

const GenreControl = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const GenreCount = styled.div`
  padding: 2px 6px;
  border-radius: 8px;
  background-color: #ffffff30;
  font-size: 14px;
  color: #bbb;
`;

// SCORE / AGE

const ScoreHeader = styled(Header)``;
const AgeHeader = styled(Header)``;

const RangeWrapper = styled.div`
  display: flex;
  padding: 4px 16px;
  gap: 8px;
`;

const RangeInput = styled.input`
  flex-grow: 1;
`;

const RangeButtons = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: center;
`;

const RangeButton = styled(ButtonAsLink)``;
const RangeButtonLabel = styled.div`
  width: 24px;
  text-align: center;
`;

// SORTS

const SortsHeader = styled(Header)``;

const Sorts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 15px;
`;

const Sort = styled.div<{$selected: boolean}>`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 4px 16px;
  color: ${p => (p.$selected ? '#a34c00' : '#bbb')};
  cursor: pointer;
  &:hover {
    background-color: #ffffff10;
  }
`;
