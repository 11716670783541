import {MouseEvent, useCallback, useMemo} from 'react';
import {styled} from 'styled-components';

import {max} from '@shared/lib/math_utils';
import {removeUndefined} from '@shared/lib/type_utils';
import {NzbsuSearchSort} from '@shared/models';

import {ButtonAsLink} from '@shared-web/components/core/button';
import {showRawModal} from '@shared-web/components/core/modal';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {chevronDownIcon} from '@shared-web/components/icons/chevron_down_icon';
import {chevronUpIcon} from '@shared-web/components/icons/chevron_up_icon';
import {downloadIcon} from '@shared-web/components/icons/download_icon';
import {infoIcon} from '@shared-web/components/icons/info_icon';
import {EmptyFragment} from '@shared-web/lib/react';

import {NzbCheck} from '@src/components/nzb_check';
import {apiCall} from '@src/lib/api';
import {formatBytes} from '@src/lib/bytes';
import {formatDate} from '@src/lib/date';
import {getCheckingQueue, queueCheck} from '@src/stores/checking_queue_store';
import {setNzbDownloadLink, useAllNzbDownloadLink} from '@src/stores/nzb_download_link_store';
import {getNzbsu, useAllNzbsu} from '@src/stores/nzbsu_store';

interface NzbTableProps {
  ids?: string[];
  sort: NzbsuSearchSort;
  onSortChange: (sortType: NzbsuSearchSort) => void;
  total?: number;
  durationSeconds?: number;
}

export const NzbTable: React.FC<NzbTableProps> = props => {
  const {ids, sort, onSortChange, total, durationSeconds} = props;
  const allNzbsu = useAllNzbsu();
  const downloadLinks = useAllNzbDownloadLink();

  const nzbItems = useMemo(
    () => (ids ? removeUndefined(ids.map(id => allNzbsu.get(id))) : undefined),
    [allNzbsu, ids]
  );

  const maxSize = max((nzbItems ?? []).map(item => item.size)) ?? 0;
  const maxPubTs = max((nzbItems ?? []).map(item => item.pubTs)) ?? 0;
  const maxGrabs = max((nzbItems ?? []).map(item => item.grabs ?? 0)) ?? 0;

  const handleDownloadClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      // Get the download URL
      const guid = evt.currentTarget.getAttribute('data-guid');
      if (guid === null) {
        return;
      }
      let link = downloadLinks.get(guid);

      // Fetch if not in store
      if (link === undefined) {
        const item = nzbItems?.find(item => item.guid === guid);
        const {url} = await apiCall('POST /get-nzb-link', {
          id: guid,
          fileName: `${item?.title ?? guid}.nzb`,
        });
        setNzbDownloadLink(guid, url);
        link = url;
      }

      // Start the download
      const element = document.createElement('a');
      element.setAttribute('href', link);
      element.setAttribute('download', `${guid}.nzb`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    [downloadLinks, nzbItems]
  );

  const handleNfoClick = useCallback(async (evt: MouseEvent<HTMLElement>) => {
    // Get the download URL
    const guid = evt.currentTarget.getAttribute('data-guid');
    if (guid === null) {
      return;
    }

    const {nfo} = await apiCall('POST /get-nzb-nfo', {id: guid});
    showRawModal({
      mode: 'fade-center',
      children: (
        <NfoModal>
          <NfoPre>{nfo}</NfoPre>
        </NfoModal>
      ),
    });
  }, []);

  const handleSortClick = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      // Get the download URL
      const sort = evt.currentTarget.getAttribute('data-sort');
      if (sort === null) {
        return;
      }
      onSortChange(sort as NzbsuSearchSort);
    },
    [onSortChange]
  );

  const handleCheckAllClick = useCallback(() => {
    const idsToCheck = ids?.filter(id => {
      const itemCheck = getCheckingQueue(id);
      const item = getNzbsu(id);
      return !item?.lastCheck && (!itemCheck || itemCheck.stage === 'failed');
    });
    for (const id of idsToCheck ?? []) {
      queueCheck(id);
    }
  }, [ids]);

  return (
    <Wrapper>
      <TableHeader $hidden={ids === undefined}>
        <div>{`${ids?.length} / ${total}`}</div>
        <div>•</div>
        <ButtonAsLink onClick={handleCheckAllClick}>Check all</ButtonAsLink>
      </TableHeader>

      <HeaderLine>
        <Name>Name</Name>
        <Nfo>Nfo</Nfo>
        <Size
          data-sort={
            sort === NzbsuSearchSort.SizeDesc ? NzbsuSearchSort.SizeAsc : NzbsuSearchSort.SizeDesc
          }
          onClick={handleSortClick}
          $sortable
        >
          <SortableCol>
            <div>Size</div>
            {sort === NzbsuSearchSort.SizeAsc ? (
              <SvgIcon icon={chevronUpIcon} color="#fff" size={12} />
            ) : sort === NzbsuSearchSort.SizeDesc ? (
              <SvgIcon icon={chevronDownIcon} color="#fff" size={12} />
            ) : (
              EmptyFragment
            )}
          </SortableCol>
        </Size>
        {durationSeconds !== undefined ? (
          <Size>
            <div>Kbps</div>
          </Size>
        ) : (
          <></>
        )}
        <PubTs
          data-sort={
            sort === NzbsuSearchSort.PostedDesc
              ? NzbsuSearchSort.PostedAsc
              : NzbsuSearchSort.PostedDesc
          }
          onClick={handleSortClick}
          $sortable
        >
          <SortableCol>
            <div>Date</div>
            {sort === NzbsuSearchSort.PostedAsc ? (
              <SvgIcon icon={chevronUpIcon} color="#fff" size={12} />
            ) : sort === NzbsuSearchSort.PostedDesc ? (
              <SvgIcon icon={chevronDownIcon} color="#fff" size={12} />
            ) : (
              EmptyFragment
            )}
          </SortableCol>
        </PubTs>
        <Grabs
          data-sort={
            sort === NzbsuSearchSort.StatsDesc
              ? NzbsuSearchSort.StatsAsc
              : NzbsuSearchSort.StatsDesc
          }
          onClick={handleSortClick}
          $sortable
        >
          <SortableCol>
            <div>Stats</div>
            {sort === NzbsuSearchSort.StatsAsc ? (
              <SvgIcon icon={chevronUpIcon} color="#fff" size={12} />
            ) : sort === NzbsuSearchSort.StatsDesc ? (
              <SvgIcon icon={chevronDownIcon} color="#fff" size={12} />
            ) : (
              EmptyFragment
            )}
          </SortableCol>
        </Grabs>
        <Check>Health</Check>
        <Download>NZB</Download>
      </HeaderLine>
      {nzbItems ? (
        nzbItems.map(item => (
          <Line key={item.guid}>
            <Name title={item.title}>{item.title}</Name>
            <Nfo>
              {item['nfo'] ? (
                <DownloadButton data-guid={item.guid} onClickAsync={handleNfoClick}>
                  <SvgIcon icon={infoIcon} size={16} color="#db7b26" />
                </DownloadButton>
              ) : (
                <></>
              )}
            </Nfo>
            <Size $highlighted={item.size === maxSize}>
              <span>{formatBytes(item.size, 1)}</span>
            </Size>
            {durationSeconds !== undefined ? (
              <Size>
                <span>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    Math.round((8 * item.size) / durationSeconds / 1024)
                  }
                </span>
              </Size>
            ) : (
              <></>
            )}
            <PubTs $highlighted={item.pubTs === maxPubTs}>
              <span>{formatDate(new Date(item.pubTs))}</span>
            </PubTs>
            <Grabs $highlighted={item.grabs === maxGrabs}>
              <span>{item.grabs ?? 0}</span>
            </Grabs>
            <Check>
              <NzbCheck guid={item.guid} />
            </Check>
            <Download>
              <DownloadButton data-guid={item.guid} onClickAsync={handleDownloadClick}>
                <SvgIcon
                  icon={downloadIcon}
                  color={downloadLinks.has(item.guid) ? '#2e6f31' : '#db7b26'}
                  size={16}
                />
              </DownloadButton>
            </Download>
          </Line>
        ))
      ) : (
        <Loading>Loading...</Loading>
      )}
    </Wrapper>
  );
};
NzbTable.displayName = 'NzbTable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  width: 100%;
`;

const TableHeader = styled.div<{$hidden: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffffaa;
  gap: 8px;
  ${p => (p.$hidden ? 'visibility: hidden;' : false)}
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  font-size: 32px;
  color: #ffffff66;
  background: #ffffff05;
  border-radius: 12px;
`;

const BaseLine = styled.div`
  display: flex;
  padding: 4px 8px;
  color: #ffffffdd;
  gap: 4px;
`;

const Line = styled(BaseLine)`
  background: #ffffff10;
  border-radius: 4px;
  &:hover {
    background-color: #ffffff20;
  }
`;

const HeaderLine = styled(BaseLine)`
  font-weight: 600;
`;

const Name = styled.div`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Col = styled.div<{$highlighted?: boolean; $sortable?: boolean}>`
  flex-shrink: 0;
  width: 70px;
  text-align: right;
  white-space: nowrap;
  ${p =>
    p.$highlighted &&
    `
    & > * {
      background: #00ff0055;
      padding: 1px 4px;
      border-radius: 8px;
    }`}
  ${p =>
    p.$sortable &&
    `
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    &:hover {
      background-color: #ffffff22;
    }
    `}
`;

const SortableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  &:first-child {
    flex-grow: 1;
  }
  &:last-child {
    flex-shrink: 0;
  }
`;

const Nfo = styled(Col)``;
const Size = styled(Col)``;
const PubTs = styled(Col)``;
const Grabs = styled(Col)``;
const Check = styled(Col)`
  text-align: left;
  width: 110px;
  margin-left: 8px;
`;
const Download = styled(Col)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadButton = styled(ButtonAsLink)`
  justify-content: center;
  width: 30px;
  height: 24px;
  border-radius: 8px;
  &:hover {
    background-color: #ffffff20;
  }
`;

const NfoModal = styled.div`
  background-color: #151921dd;
  color: #ffffffaa;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 64px);
  padding: 32px;
  border-radius: 8px;
  overflow: auto;
`;
const NfoPre = styled.pre`
  font-family: monospace;
`;
