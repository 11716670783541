import {createTheme} from '@shared-web/theme/theme_base';
import {FrontendTheme} from '@shared-web/theme/theme_model';

export const theme: FrontendTheme = createTheme({
  button: {
    backgroundActive: '#a34c00',
    backgroundHover: '#a05a1d',
    backgroundDisabled: '#ac8b6e',
    backgroundLoading: '#ac8b6e',
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 17,
    fontWeight: 600,
  },
  link: {
    textColorActive: '#db7b26',
    textColorHover: '#db7b26',
    textColorDisabled: '#da9960',
    textColorLoading: '#da9960',
  },
  input: {
    paddingLeft: 8,
    paddingRight: 8,
    height: 40,
    fontSize: 18,
    borderColor: '#ffffff55',
    hoverBorderColor: '#ffffff55',
    focusBorderColor: '#a34c00',
    focusOutlineColor: '#a34c0033',
    borderWidth: 2,
    focusBorderWidth: 2,
    backgroundColor: '#151921',
    backgroundColorHover: '#15192133',
    textColor: '#fff',
  },
});
