import {useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {Button} from '@shared-web/components/core/button';

import {SearchBar} from '@src/components/search_bar';
import {TmdbTile} from '@src/components/tmdb_tile';
import {useTmdbCount} from '@src/stores/tmdb_count_store';
import {useFilteredItems} from '@src/stores/tmdb_search_bar_store';
import {startFetching} from '@src/stores/tmdb_store';

export const BrowseMoviesPage: React.FC = () => {
  const [limit, setLimit] = useState(100);
  const tmdb = useFilteredItems();
  const tmdbCount = useTmdbCount();

  const byPubTs = useMemo(() => tmdb.slice(0, limit), [limit, tmdb]);
  const handleShowMoreClick = useCallback(() => {
    setLimit(current => current * 2);
  }, []);

  useEffect(() => startFetching(), []);

  if (tmdbCount === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <StyledSearchBar />
      <Content>
        <Grid>
          {byPubTs.map(movie => (
            <TmdbTile key={movie.imdbId} tmdb={movie} />
          ))}
        </Grid>
        {tmdb.length > limit ? (
          <ShowMoreButton onClick={handleShowMoreClick}>SHOW MORE</ShowMoreButton>
        ) : (
          <></>
        )}
      </Content>
    </Wrapper>
  );
};
BrowseMoviesPage.displayName = 'BrowseMoviesPage';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const StyledSearchBar = styled(SearchBar)`
  flex-shrink: 0;
  position: sticky;
  top: 16px;
  overflow: auto;
  max-height: calc(100vh - 32px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

const ShowMoreButton = styled(Button)`
  width: 100%;
`;
