import {useMemo} from 'react';

import {FrontendTmdb} from '@shared/api/website_api';
import {neverHappens} from '@shared/lib/type_utils';

import {createDataStore} from '@shared-web/lib/data_store';

import {MIN_VOTE_COUNT} from '@src/lib/constants';
import {useAllTmdb} from '@src/stores/tmdb_store';

export enum TmdbSort {
  Recents = 'recents',
  ReleaseDate = 'release-date',
  Score = 'score',
  Popularity = 'popularity',
  Budget = 'budget',
  BoxOffice = 'box-office',
}

export enum GenreFilter {
  Include = 'include',
  Exclude = 'exclude',
}

const tmdbSearchBarStore = createDataStore<{
  genres: {name: string; mode: GenreFilter}[];
  minScore: number;
  maxAge: number;
  sort: TmdbSort;
  title: string;
}>({genres: [], minScore: 0, maxAge: Number.POSITIVE_INFINITY, sort: TmdbSort.Recents, title: ''});

export const useTmdbSearchBar = tmdbSearchBarStore.useData;
export const updateTmdbSearchBar = tmdbSearchBarStore.updateData;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SIX_MONTHS = 6 * 30 * 24 * 3600 * 1000;

export const useFilteredItems = (): FrontendTmdb[] => {
  const allTmdb = useAllTmdb();
  const {title, genres, minScore, maxAge, sort} = useTmdbSearchBar();

  const items = useMemo(() => [...allTmdb.values()], [allTmdb]);
  const filteredItems = useMemo(() => {
    const filtered = items
      .filter(item => {
        if (title.length === 0) {
          return true;
        }
        return item.title.toUpperCase().includes(title.toUpperCase());
      })
      .filter(item => {
        if (minScore === 0) {
          return true;
        }
        if (item.voteAverage === undefined || (item.voteCount ?? 0) < MIN_VOTE_COUNT) {
          return false;
        }
        return 10 * item.voteAverage >= minScore;
      })
      .filter(item => {
        const itemAgeMs = Date.now() - (item.releaseDate ?? 0);
        return itemAgeMs <= maxAge;
      })
      .filter(item => {
        if (genres.length === 0) {
          return true;
        }
        const itemGenres = new Set(item.genres.map(g => g.name));
        const includeGenres = genres.filter(g => g.mode === GenreFilter.Include);
        const excludeGenres = genres.filter(g => g.mode === GenreFilter.Exclude);

        const includePassed =
          includeGenres.length === 0 ||
          includeGenres.find(g => itemGenres.has(g.name)) !== undefined;
        const excludePassed =
          excludeGenres.length === 0 ||
          excludeGenres.find(g => itemGenres.has(g.name)) === undefined;

        return includePassed && excludePassed;
      });

    if (sort === TmdbSort.Recents) {
      const sixMonthOld = filtered.filter(
        m => m.releaseDate !== undefined && Date.now() - m.releaseDate <= SIX_MONTHS
      );
      const notSixMonthOld = filtered.filter(
        m => m.releaseDate === undefined || Date.now() - m.releaseDate > SIX_MONTHS
      );
      return [
        ...sixMonthOld.sort((m1, m2) => m2.firstPubTs - m1.firstPubTs),
        ...notSixMonthOld.sort((m1, m2) => (m2.releaseDate ?? 0) - (m1.releaseDate ?? 0)),
      ];
    }
    if (sort === TmdbSort.ReleaseDate) {
      return filtered.sort((m1, m2) => {
        return (m2.releaseDate ?? 0) - (m1.releaseDate ?? 0);
      });
    }
    if (sort === TmdbSort.Score) {
      return filtered.sort((m1, m2) => {
        const v1 = (m1.voteCount ?? 0) >= MIN_VOTE_COUNT ? m1.voteAverage ?? 0 : 0;
        const v2 = (m2.voteCount ?? 0) >= MIN_VOTE_COUNT ? m2.voteAverage ?? 0 : 0;
        return v2 - v1;
      });
    }
    if (sort === TmdbSort.Popularity) {
      return filtered.sort((m1, m2) => {
        return (m2.popularity ?? 0) - (m1.popularity ?? 0);
      });
    }
    if (sort === TmdbSort.Budget) {
      return filtered.sort((m1, m2) => {
        return (m2.budget ?? 0) - (m1.budget ?? 0);
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sort === TmdbSort.BoxOffice) {
      return filtered.sort((m1, m2) => {
        return (m2.revenue ?? 0) - (m1.revenue ?? 0);
      });
    }

    neverHappens(sort);
  }, [items, sort, title, minScore, maxAge, genres]);

  return filteredItems;
};
