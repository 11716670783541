import {styled} from 'styled-components';

import {Custom, EmptyFragment} from '@shared-web/lib/react';

import {MIN_VOTE_COUNT} from '@src/lib/constants';

interface VoteScoreProps {
  voteCount?: number;
  voteAverage?: number;
  size?: number;
  thickness?: number;
}

export const DEFAULT_SIZE = 32;
const DEFAULT_THICKNESS = 2;
const COLORS = {
  green: {main: '#21d07a', back: '#204528'},
  yellow: {main: '#d3d530', back: '#423d0f'},
  red: {main: '#db2360', back: '#571435'},
};

export const VoteScore: Custom<VoteScoreProps, 'div'> = props => {
  const {
    voteAverage,
    voteCount,
    size = DEFAULT_SIZE,
    thickness = DEFAULT_THICKNESS,
    ...rest
  } = props;
  if (voteAverage === undefined || voteCount === undefined || voteCount < MIN_VOTE_COUNT) {
    return EmptyFragment;
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const color = voteAverage >= 7 ? COLORS.green : voteAverage >= 4 ? COLORS.yellow : COLORS.red;

  return (
    <Wrapper
      title={`${voteCount.toLocaleString()} votes`}
      $size={size}
      $thickness={thickness}
      {...rest}
    >
      <svg viewBox={`0 0 ${size} ${size}`} transform="rotate(-90)">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          stroke={color.back}
        ></circle>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={(size - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
          stroke={color.main}
          strokeLinecap="round"
          strokeDashoffset={Math.PI * (size - thickness) * -(1 + voteAverage / 10)}
          strokeDasharray={Math.PI * (size - thickness)}
        ></circle>
      </svg>
      <Center
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        $fontSize={size / 2.5}
      >
        {Math.round(voteAverage * 10)}
      </Center>
    </Wrapper>
  );
};

VoteScore.displayName = 'VoteScore';

const Wrapper = styled.div<{$size: number; $thickness: number}>`
  position: relative;
  width: ${p => p.$size + 2 * p.$thickness}px;
  height: ${p => p.$size + 2 * p.$thickness}px;
  border: solid 2px #151921;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #151921;
`;

const Center = styled.div<{$fontSize: number}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${p => p.$fontSize}px;
  font-weight: 600;
  color: #ffffffd0;
`;
