import {NzbPreview} from '@shared/api/website_api';
import {NzbsuVersion, TmdbVersion} from '@shared/constant';
import {Brand} from '@shared/lib/type_utils';

export type UserId = Brand<'UserId', string>;

export interface UserItem {
  id: UserId;
  hash: string;
  salt: string;
  sessionDuration: number; // in seconds
}

interface NzbsuBaseItem {
  guid: string;
  title: string;
  size: number;
  pubTs: number;
  imdb: string;
  v: NzbsuVersion;
  nzb: string;
}

export type NzbsuRawItem = NzbsuBaseItem & Record<string, string>;

export enum NzbsuSearchSort {
  SizeDesc = 'size_desc',
  SizeAsc = 'size_asc',
  PostedDesc = 'posted_desc',
  PostedAsc = 'posted_asc',
  StatsDesc = 'stats_desc',
  StatsAsc = 'stats_asc',
}

//

export interface TmdbItem {
  imdb_id: string;
  v: TmdbVersion;
  firstPubTs: number;
  firstNzb: NzbPreview;
  lastNzb: NzbPreview;
  largestNzb: NzbPreview;
  lastUpdate: number;
  [key: string]: unknown;
}
