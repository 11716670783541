export const REGION = 'eu-west-3' as string;
export const CODE_BUCKET = 'cinema-code-20231222154200608600000001' as string;
export const ACCOUNT_ID = '982619301361' as string;
export const ADMINISTRATOR_ROLE_ARN =
  'arn:aws:iam::982619301361:role/aws-reserved/sso.amazonaws.com/AWSReservedSSO_AdministratorAccess_b208c5425ea07e0d' as string;
export const CINEMA_LOCK_INDEX_NAME = {};
export const CINEMA_LOCK_TABLE_NAME = 'CinemaLock' as string;
export const CINEMA_USER_INDEX_NAME = {};
export const CINEMA_USER_SESSION_INDEX_NAME = {};
export const CINEMA_USER_SESSION_TABLE_NAME = 'CinemaUserSession' as string;
export const CINEMA_USER_TABLE_NAME = 'CinemaUser' as string;
export const NZB_BUCKET = 'nzb-20231224084834382100000002' as string;
export const NZB_CHECK_LAST_RESULT_INDEX_NAME = {};
export const NZB_CHECK_LAST_RESULT_TABLE_NAME = 'NzbCheckLastResult' as string;
export const NZB_CHECK_RESULT_INDEX_NAME = {
  NZBCHECKRESULT_BY_NZBID_SORTED_BY_TS: 'NzbCheckResult_ByNzbId_SortedByTs',
};
export const NZB_CHECK_RESULT_TABLE_NAME = 'NzbCheckResult' as string;
export const NZBSU_DUMP_FUNCTION_NAME = 'cinema-nzbsu_dump' as string;
export const NZBSU_DUMP_ROLE_ARN =
  'arn:aws:iam::982619301361:role/cinema-nzbsu_dump-role' as string;
export const NZBSU_INDEX_NAME = {
  NZBSU_BY_IMDB_SORTED_BY_PUBTS: 'Nzbsu_ByImdbId_SortedByPubTs',
  NZBSU_BY_NZB_SORTED_BY_PUBTS: 'Nzbsu_Nzb_SortedByPubTs',
  NZBSU_BY_V_SORTED_BY_PUBTS: 'Nzbsu_All_SortedByPubTs',
};
export const NZBSU_TABLE_NAME = 'Nzbsu' as string;
export const OPENSUBTITLES_BUCKET = 'cinema-opensubtitles-20240204141631023700000001' as string;
export const TMDB_DUMP_FUNCTION_NAME = 'cinema-tmdb_dump' as string;
export const TMDB_DUMP_ROLE_ARN = 'arn:aws:iam::982619301361:role/cinema-tmdb_dump-role' as string;
export const TMDB_INDEX_NAME = {
  TMDB_BY_V_SORTED_BY_FIRSTPUBTS: 'Tmdb_All_SortedByFirstPubTs',
  TMDB_BY_V_SORTED_BY_LASTUPDATE: 'Tmdb_All_SortedByLastUpdate',
  TMDB_BY_V_SORTED_BY_RELEASE_DATE: 'Tmdb_All_SortedByReleaseDate',
};
export const TMDB_TABLE_NAME = 'Tmdb' as string;
export const WEBSITE_BACKEND_FUNCTION_NAME = 'cinema-website_backend' as string;
export const WEBSITE_BACKEND_ROLE_ARN =
  'arn:aws:iam::982619301361:role/cinema-website_backend-role' as string;
export const WEBSITE_BACKEND_URL = 'https://cinema.matthis.link/' as string;
export const WEBSITE_FRONTEND_CLOUDFRONT_DOMAIN_NAME = 'd1loghou6nl821.cloudfront.net' as string;
export const NODE_ENV = 'production' as string;
