import {MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {randomStringUnsafe} from '@shared/lib/random_utils';
import {NonEmptyArray} from '@shared/lib/type_utils';
import {NzbsuSearchSort} from '@shared/models';

import {Button} from '@shared-web/components/core/button';
import {Input} from '@shared-web/components/core/input';

import {NzbTable} from '@src/components/nzb_table';
import {apiCall} from '@src/lib/api';
import {batchSetNzbsu, sortNzbsuIds} from '@src/stores/nzbsu_store';

interface ResState {
  query: string;
  itemIds: string[];
  offset: number;
  total: number;
}

const CATS: NonEmptyArray<{id: number; label: string}> = [
  {id: 2045, label: 'Movies UHD'},
  {id: 2000, label: 'Movies All'},
  {id: 5045, label: 'TV UHD'},
  {id: 5000, label: 'TV All'},
];

export const NzbsuPage: React.FC = () => {
  const [res, setRes] = useState<ResState | undefined>();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [refreshId, setRefreshId] = useState(randomStringUnsafe(10));
  const [resortId, setResortId] = useState(randomStringUnsafe(10));
  const sort = useRef(NzbsuSearchSort.SizeDesc);
  const cat = useRef(CATS[0]);

  const refresh = useCallback(() => setRefreshId(randomStringUnsafe(10)), []);
  const resort = useCallback(() => setResortId(randomStringUnsafe(10)), []);

  useEffect(() => {
    const query = searchTextRef.current;
    if (query.length === 0) {
      return;
    }
    setRes(undefined);
    setLoading(true);
    apiCall('POST /search-nzbsu', {query, cat: cat.current.id, offset: 0, sort: sort.current})
      .then(res => {
        const {items, offset, total} = res;
        batchSetNzbsu(items.map(item => ({key: item.guid, value: item})));
        setRes({itemIds: items.map(item => item.guid), offset, total, query});
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [refreshId]);

  const searchTextRef = useRef(searchText);
  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  const handleSearchClick = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleLoadMoreClick = useCallback(() => {
    if (!res) {
      return;
    }
    setLoading(true);
    apiCall('POST /search-nzbsu', {
      query: res.query,
      cat: cat.current.id,
      offset: res.offset + res.itemIds.length,
      sort: sort.current,
    })
      .then(res =>
        setRes(current => {
          if (!current) {
            return;
          }
          batchSetNzbsu(res.items.map(item => ({key: item.guid, value: item})));
          return {
            itemIds: [...current.itemIds, ...res.items.map(item => item.guid)],
            offset: res.offset,
            total: current.total,
            query: current.query,
          };
        })
      )
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [res]);

  const handleSortChange = useCallback(
    (newSort: NzbsuSearchSort) => {
      sort.current = newSort;
      // Only refresh if we don't have all the items, otherwise sorting in the frontend is enough
      if (!res || res.itemIds.length < res.total) {
        refresh();
      } else {
        resort();
      }
    },
    [refresh, res, resort]
  );

  const handleCatClick = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      const newCat = CATS.find(c => String(c.id) === evt.currentTarget.getAttribute('data-cat'));
      if (!newCat) {
        return;
      }
      cat.current = newCat;
      refresh();
    },
    [refresh]
  );

  const sortedIds = useMemo(
    () => (res ? sortNzbsuIds(res.itemIds, sort.current) : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res, resortId]
  );

  return (
    <Wrapper>
      <ButtonLine>
        {CATS.map(el => (
          <ButtonLineItem
            key={el.id}
            data-cat={el.id}
            $selected={el.id === cat.current.id}
            onClick={handleCatClick}
          >
            {el.label}
          </ButtonLineItem>
        ))}
      </ButtonLine>

      <Form>
        <Input
          value={searchText}
          syncState={setSearchText}
          autoFocus
          placeholder="Search something"
          width="100%"
          overrides={{paddingLeft: 16, paddingRight: 16, fontSize: 20, fontWeight: 700, height: 57}}
        />
        <Button onClick={handleSearchClick} loading={loading} keyboardSubmit>
          SEARCH
        </Button>
      </Form>

      {sortedIds || loading ? (
        <NzbTable
          ids={sortedIds}
          sort={sort.current}
          total={res?.total}
          onSortChange={handleSortChange}
        />
      ) : (
        <></>
      )}

      {res && sortedIds && sortedIds.length < res.total ? (
        <LoadMoreButton onClick={handleLoadMoreClick} loading={loading}>
          LOAD MORE
        </LoadMoreButton>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
NzbsuPage.displayName = 'HomePage';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.div`
  display: flex;
  gap: 16px;
`;

const ButtonLine = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
`;

const ButtonLineItem = styled.div<{$selected: boolean}>`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: #ffffff11;
  border-radius: 16px;
  border: solid 2px ${p => (p.$selected ? '#a34c00' : 'transparent')};
  &:hover {
    background: #ffffff22;
  }
`;

const LoadMoreButton = styled(Button)`
  width: 100%;
`;
