import {FC} from 'react';
import {styled} from 'styled-components';

import {FrontendTmdb, TmdbVideo} from '@shared/api/website_api';

import {Link} from '@shared-web/components/core/button';

import {VoteScore} from '@src/components/vote_score';
import {formatReleaseDate, formatRuntime} from '@src/lib/date';

interface MovieDetailsProps {
  movie: FrontendTmdb;
  videos?: TmdbVideo[];
}

export const MovieDetails: FC<MovieDetailsProps> = props => {
  const {movie, videos} = props;

  const trailers = videos
    ?.filter(v => ['Trailer', 'Teaser'].includes(v.type) && v.site === 'YouTube')
    .sort((v1, v2) => {
      const typeScore1 = v1.type === 'Trailer' ? 10 : 1;
      const typeScore2 = v2.type === 'Trailer' ? 10 : 1;
      if (typeScore1 !== typeScore2) {
        return typeScore2 - typeScore1;
      }
      return new Date(v2.published_at).getTime() - new Date(v1.published_at).getTime();
    });

  return (
    <Wrapper>
      <BackdropSection $backdropPath={movie.backdropPath}>
        <BackdropOverlay />
        <PosterImage src={`https://image.tmdb.org/t/p/w500${movie.posterPath}`} />
        <Content>
          <TitleLine>
            <Title>{movie.title}</Title>
            <Year>{`(${new Date(movie.releaseDate ?? 0).getFullYear()})`}</Year>
          </TitleLine>
          <InfoLine>
            <ReleaseDate>{formatReleaseDate(movie.releaseDate)}</ReleaseDate>
            <Sep>•</Sep>
            <Genres>{movie.genres.map(g => g.name).join(', ')}</Genres>
            <Sep>•</Sep>
            <Runtime>{formatRuntime(movie.runtime)}</Runtime>
          </InfoLine>
          <VoteLine>
            <VoteScore
              voteAverage={movie.voteAverage}
              voteCount={movie.voteCount}
              size={64}
              thickness={4}
            />
            <VoteInfo>
              <VoteCount>{`${(movie.voteCount ?? 0).toLocaleString()} votes`}</VoteCount>
              <div>{`Budget: $${movie.budget?.toLocaleString()}`}</div>
              <div>{`Revenue: $${movie.revenue?.toLocaleString()}`}</div>
            </VoteInfo>
          </VoteLine>
          <Tagline>{movie.tagline}</Tagline>
          <SectionTitle>Overview</SectionTitle>
          <OverviewText>{movie.overview}</OverviewText>
          <SectionTitle>Videos</SectionTitle>
          <Videos>
            {trailers === undefined ? (
              <Loading>Loading videos...</Loading>
            ) : trailers.length === 0 ? (
              <Loading>Aucune videos</Loading>
            ) : (
              <>
                {trailers.map(v => (
                  <Video
                    key={v.id}
                    href={`https://www.youtube.com/watch?v=${v.key}`}
                    target="_blank"
                  >
                    <VideoName>{v.name}</VideoName>
                    <VideoType>{v.type}</VideoType>
                  </Video>
                ))}
              </>
            )}
          </Videos>
        </Content>
      </BackdropSection>
    </Wrapper>
  );
};

MovieDetails.displayName = 'MovieDetails';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -16px;
`;

const BackdropSection = styled.div<{$backdropPath?: string}>`
  position: relative;
  width: 100%;
  ${p =>
    p.$backdropPath !== undefined &&
    `background-image: url('https://image.tmdb.org/t/p/w1280${p.$backdropPath}');`}
  display: flex;
  gap: 32px;
  padding: 32px;
`;

const BackdropOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    rgb(9, 26, 26),
    calc(50vw - 510px),
    rgba(9, 26, 26, 0.84) 50%,
    rgba(9, 26, 26, 0.84) 100%
  );
`;

const PosterImage = styled.img`
  height: 500px;
  border-radius: 8px;
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  color: #ffffffdd;
  font-weight: 100;
`;

const TitleLine = styled.div`
  display: flex;
  gap: 10px;
  font-size: 35px;
`;
const Title = styled.div`
  color: #ffffff;
  font-weight: 600;
`;
const Year = styled.div`
  color: #ffffffaa;
`;

const InfoLine = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
`;
const ReleaseDate = styled.div``;
const Sep = styled.div``;
const Genres = styled.div``;
const Runtime = styled.div``;

const VoteLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  margin-top: 18px;
`;
const VoteCount = styled.div``;

const VoteInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  color: #ffffffaa;
`;

const Tagline = styled.div`
  color: #ffffff99;
  font-style: italic;
  margin-top: 18px;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  margin-top: 11px;
  font-weight: 500;
`;

const OverviewText = styled.div`
  margin-top: 6px;
`;

const Videos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 6px;
`;

const Loading = styled.div`
  font-style: italic;
  color: #ffffff33;
  font-weight: 500;
`;

const Video = styled(Link)`
  display: flex;
  align-items: baseline;
  gap: 8px;
  &:hover {
    text-decoration: none;
    & > *:first-child {
      text-decoration: underline;
    }
  }
`;
const VideoName = styled.span``;
const VideoType = styled.span`
  background: #ffffff33;
  padding: 2px 9px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffffbb;
`;
