import {AllApiSchema} from '@shared/api/core/api_schema';
import {ApiConfig, ApiName} from '@shared/api/core/api_types';
import {WEBSITE_API} from '@shared/api/website_api';
import {WEBSITE_BACKEND_URL} from '@shared/env';

export const ALL = {
  website_backend: WEBSITE_API,
} satisfies AllApiSchema;

export const API_CONFIGS = {
  website_backend: {host: WEBSITE_BACKEND_URL},
} satisfies Record<ApiName, ApiConfig>;
