import {FC} from 'react';
import {styled} from 'styled-components';

import {FrontendTmdb} from '@shared/api/website_api';

import {UnthemedNavLink} from '@shared-web/components/core/button';

import {VoteScore} from '@src/components/vote_score';
import {formatReleaseDate} from '@src/lib/date';

interface TmdbTileProps {
  tmdb: FrontendTmdb;
}

const VoteScoreSize = 32;

export const TmdbTile: FC<TmdbTileProps> = props => {
  const {tmdb} = props;
  const {imdbId, title, posterPath, releaseDate, genres, voteAverage, voteCount} = tmdb;
  return (
    <Wrapper to={`/browse/${imdbId}`}>
      <ImageWrapper>
        <Image src={`https://image.tmdb.org/t/p/w200${posterPath}`} />
        <StyledVoteScore voteAverage={voteAverage} voteCount={voteCount} size={VoteScoreSize} />
      </ImageWrapper>
      <Info>
        <Title title={title}>{title}</Title>
        <ReleaseDate>{formatReleaseDate(releaseDate)}</ReleaseDate>
        <Genre>{genres.map(g => g.name).join(', ')}</Genre>
      </Info>
    </Wrapper>
  );
};

TmdbTile.displayName = 'TmdbTile';

const Wrapper = styled(UnthemedNavLink)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px #ffffff33;

  &:hover {
    transition: transform 150ms ease-in-out;
    transform: scale(1.02);
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #ffffffdd;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Image = styled.img`
  width: 100%;
`;

const StyledVoteScore = styled(VoteScore)`
  position: absolute;
  right: 4px;
  bottom: -${VoteScoreSize / 2}px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
`;

const ReleaseDate = styled.div`
  color: #ffffff99;
  font-size: 14px;
`;

const Genre = styled.div`
  font-size: 14px;
  color: #ffffff99;
`;
