import {styled} from 'styled-components';
import {Route} from 'wouter';

import {Modal} from '@shared-web/components/core/modal';

import {BrowseMoviePage} from '@src/components/browse_movie_page';
import {BrowseMoviesPage} from '@src/components/browse_movies_page';
import {NavBar} from '@src/components/nav_bar';
import {NzbsuPage} from '@src/components/nzbsu_page';

export const App: React.FC = () => {
  return (
    <Route path="/" nest>
      <>
        <Wrapper>
          <NavBar />
          <Route path="/" component={NzbsuPage} />
          <Route path="/browse" component={BrowseMoviesPage} />
          <Route path="/browse/:imdbId" component={BrowseMoviePage} />
        </Wrapper>
        <Modal />
      </>
    </Route>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #151921;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  overflow-y: auto;
`;
